import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { StepViewHidden } from '../StepViewHidden';

import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';
import { Spinner, SpinnerTypes } from 'wix-ui-tpa/cssVars';
import { Fullscreen } from '../Fullscreen/Fullscreen';
import utils, { getFlatStepsList } from '../../views/utils';

import { FedopsInteraction } from '../../../../../config/fedopsInteraction';
import { useSingleEndInteraction } from '../../../../../contexts/Monitoring/MonitoringContext';
import TextWithDirection from '../../../../../components-shared/TextWithDirection';

import { Ricos } from '../../../../../components-shared/Ricos/loadable/Ricos';
import { FCWithChildren } from '@wix/challenges-web-library';
import {
  IParticipantSectionsContext,
  useSections,
} from '../../../../../contexts/ParticipantSections/ParticipantSectionsContext';
import { classes, st } from './SideBarStepView.st.css';
import { useScrollToTopOnMount } from '../../../../../hooks/useScrollToTopOnMount';
import { getDetails, getTitle } from '../../../../../selectors/step';
import challengeSettings from '../../../../ChallengesPage/settingsParams';
import { useSettings } from '@wix/tpa-settings/react';
import { TextAlignment } from '../../../../ChallengesPage/Settings/challengeSettings/challengeSettings.types';

export interface ISideBarStepViewProps {
  dataHook?: string;
  challenge: Challenge;
  isListParticipantSectionsRequestInProgress: boolean;
  isParticipantStepsLoading: boolean;
  currentStep: IParticipantSectionsContext['selectedStep'];
  listParticipantSections: IParticipantSectionsContext['listParticipantSections'];
  participantSteps: IParticipantSectionsContext['participantSteps'];
  goToCurrentStep(e: any): void;
  Breadcrumbs: () => React.ReactElement;
  StepShareButton: () => React.ReactElement;
  pageRef: React.RefObject<HTMLElement>;
}

export const SideBarStepView: FCWithChildren<ISideBarStepViewProps> = (
  props,
) => {
  const { isMobile } = useEnvironment();
  const {
    challenge,
    isListParticipantSectionsRequestInProgress,
    isParticipantStepsLoading,
    currentStep,
    listParticipantSections,
    participantSteps,
    Breadcrumbs,
    StepShareButton,
    pageRef,
  } = props;
  const stepId = currentStep?.id;
  const { participantSteps: pSteps, requestParticipantStep } = useSections();
  const flatStepsList = getFlatStepsList({
    listParticipantSections,
    participantSteps,
  });
  const contentContainerRef = React.useRef<HTMLDivElement>(null);
  const originalStep =
    stepId && pSteps?.steps?.find((step) => step.id === stepId); // we need to get an original step here because it will have the entire data
  const isStepHidden = utils.isStepHidden(
    challenge,
    flatStepsList,
    // TODO: check why currentStep helped instead of originalStep. was fixing: https://wix.slack.com/archives/CQF1SC6MN/p1705578648983029
    currentStep,
  );

  const challengeStep = originalStep?.source;
  const isDelayedDetails =
    !!challengeStep && getDetails(challengeStep) === undefined; // only `undefined` is suitable here
  const settings = useSettings();
  const alignment = settings.get(challengeSettings.sidebarLayoutTextAlignment);
  const isRTL = alignment === TextAlignment.Right;

  React.useEffect(() => {
    if (isDelayedDetails && !isStepHidden) {
      void requestParticipantStep?.(stepId, flatStepsList);
    }
  }, [stepId]);

  useSingleEndInteraction(FedopsInteraction.ViewStepAtSidebarLayout, stepId);
  useScrollToTopOnMount(contentContainerRef);

  if (!currentStep) {
    return null;
  }

  return isListParticipantSectionsRequestInProgress ||
    isParticipantStepsLoading ? (
    <Spinner type={SpinnerTypes.slim} isCentered={false} />
  ) : (
    <div
      id={stepId}
      className={st(classes.root, {
        mobile: isMobile,
        rtl: isRTL,
      })}
      data-hook={props.dataHook || null}
      ref={contentContainerRef}
    >
      {!isStepHidden ? (
        <>
          <Breadcrumbs />
          <Fullscreen pageRef={pageRef} />

          <TextWithDirection>
            <h3 className={classes.stepViewTitle}>
              {getTitle(challengeStep)}
              {isMobile ? (
                <div className={classes.stepViewTitleShare}>
                  <StepShareButton />
                </div>
              ) : null}
            </h3>
          </TextWithDirection>

          {isDelayedDetails ? (
            <Spinner
              type={SpinnerTypes.slim}
              isCentered={!isMobile}
              className={classes.spinner}
            />
          ) : (
            <Ricos
              key={stepId}
              details={getDetails(challengeStep)}
              contentId={stepId}
              context="program_step"
              dataHook="program-step"
            />
          )}
        </>
      ) : (
        <StepViewHidden
          date={originalStep?.dateFrame?.start}
          goToCurrentStep={(e) => props.goToCurrentStep(e)}
        />
      )}
    </div>
  );
};
