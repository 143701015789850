import React from 'react';

import { useEnvironment, useTranslation, useBi } from '@wix/yoshi-flow-editor';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';
import { useSettings } from '@wix/tpa-settings/react';
import challengeSettings from '../../../settingsParams';
import { ButtonNames } from '../../../../../contexts/main/biInterfaces';

import { convertTextAlignmentToPopoverPlacement } from '../../../../../services/settingsHelpers';
import { Dots } from '../Dots';
import { classes, st } from './ChallengeDots.st.css';
import utils from '../../views/utils';
import { dataHooks } from '@wix/data-hooks';
import { useInviteLink } from '../../../../../contexts/InviteLink/InviteLinkContext';
import {
  useCSSPBEnabled,
  useCSSPBStyle,
} from '../../../../../hooks/useCSSPBStyle';
import { useGeneralData } from '../../../../../contexts/GeneralDataProvider/GeneralDataContext';

export interface IChallengeDotsProps {
  className?: string;
}

export const challengeDotsDataHooks = dataHooks<{
  main;
}>('challenge-dots');

export const ChallengeDots: React.FunctionComponent<IChallengeDotsProps> = ({
  className,
}: IChallengeDotsProps) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const alignment = utils.getHeaderAlignByType(settings);
  const { goToInviteLink } = useInviteLink();
  const bi = useBi();
  const { isParticipantPageAvailable } = useGeneralData();
  const getCSSPBStyle = useCSSPBStyle();

  const cssPBEnabled = useCSSPBEnabled();
  const isDisplayOneAppSettingEnabled = settings.get(
    challengeSettings.joinViaOneAppForParticipantIsEnabled,
  );
  const showOneApp = cssPBEnabled || isDisplayOneAppSettingEnabled;

  // will remove in 2-3 weeks
  console.log('---- oprog dots visibility', {
    isParticipantPageAvailable,
    isDisplayOneAppSettingEnabled,
    cssPBEnabled,
    showOneApp,
    oldSetting: settings.get(challengeSettings.displayOneApp),
    newSetting: settings.get(
      challengeSettings.joinViaOneAppForParticipantIsEnabled,
    ),
  });

  if (!showOneApp) {
    // Now we have only one menu item, so the entire menu should be hidden, if this menu item isn't visible.
    return null;
  }

  return (
    <div
      className={`${st(classes.root, {
        mobile: isMobile,
        newParticipantPage: isParticipantPageAvailable,
        alignment,
      })} ${className}`}
      style={getCSSPBStyle({
        displayVar: '--opgJoinViaOneAppForParticipantIsEnabled',
      })}
      data-hook={challengeDotsDataHooks.main()}
    >
      <Dots
        menuItems={[
          {
            title: t('challenge.page.join-via', {
              wixApp: t('challenge.wix-app'),
            }),
            isHidden: !showOneApp,
            callback: async () => {
              await bi.report(
                memberWebAppButtonClickV2({
                  buttonName: ButtonNames.GoToOneApp,
                }),
              );

              goToInviteLink();
            },
          },
        ]}
        popoverPlacement={convertTextAlignmentToPopoverPlacement(alignment)}
        onOpenMenu={async () => {
          await bi.report(
            memberWebAppButtonClickV2({
              buttonName: ButtonNames.OpenDotsMenu,
            }),
          );
        }}
      />
    </div>
  );
};
ChallengeDots.displayName = 'ChallengeDots';
ChallengeDots.defaultProps = {
  className: '',
};
