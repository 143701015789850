import { Participant, ParticipantV3 } from '../../types/v3Types';
import type { Participant as ParticipantV1 } from '@wix/ambassador-challenges-v1-participant/types';
import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';
import { isSelfPaced } from '../isSelfPaced';

export function isV3Participant(participant: Participant) {
  return (participant as ParticipantV3)?.revision !== undefined;
}
export function getState(participant: Participant) {
  if (isV3Participant(participant)) {
    return (participant as ParticipantV3).state;
  }
  return (participant as ParticipantV1)?.transitions?.[0]?.state;
}

export function getStartDate(participant: Participant) {
  if (isV3Participant(participant)) {
    const { joinedOptions, createdDate } = participant as ParticipantV3;
    return joinedOptions?.startDate || new Date(createdDate).toISOString();
  }
  return (participant as ParticipantV1).dateFrame?.start;
}

export function getProgress(participant: Participant) {
  if (!participant) {
    return 0;
  }
  if (isV3Participant(participant)) {
    const { progress } = participant as ParticipantV3;
    return progress?.completionPercentage || 0;
  }
  const { stepsSummary } = participant as ParticipantV1;
  return stepsSummary?.completedStepsNumber
    ? (stepsSummary.completedStepsNumber / stepsSummary.stepsNumber) * 100
    : 0;
}

export function isSelfPacedCompleted(
  challenge: Challenge,
  participant: Participant,
) {
  return (
    !!challenge && isSelfPaced(challenge) && getProgress(participant) === 100
  );
}
