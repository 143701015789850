import type { IUser } from '@wix/native-components-infra/dist/src/types/types';
import {
  State as ParticipantState,
  Participant,
} from '@wix/ambassador-challenges-v1-participant/types';

import {
  CancelJoinRequestFunction,
  JoinFunction,
  LeaveTheChallengeFunction,
} from './userContextInterface';
import { IUserProviderProps } from './UserProvider';
import { UpdatedUserData } from './helpers/userContextHelpers';
import { useControllerData } from '../main/controllerContext';
import { ParticipantV3 } from '../../types/v3Types';

// https://www.wix.com/corvid/reference/wix-users.html#LoginOptions
export interface IPromptLogin {
  mode?: 'login' | 'signup';
  lang?: string;
}

export enum UserState {
  VISITOR = 'VISITOR',
  MEMBER = 'MEMBER',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
}

export type IUserType = UserState | ParticipantState | ParticipantV3['state'];
export type PlatformUser = Pick<IUser, 'id' | 'instance' | 'loggedIn' | 'role'>;
export interface IUserContext {
  user: PlatformUser & { email: string };
  userType: IUserType;
  isParticipantInSuspendedState?: boolean;
  isParticipantInLockedState?: boolean;
  participant?: Participant;
  participantV3?: ParticipantV3;
  promptLogin(options?: IPromptLogin): Promise<any>;
  join: JoinFunction;
  cancelJoinRequest: CancelJoinRequestFunction;
  leaveProgram: (participantId: string, challengeId: string) => Promise<void>;
  leaveTheChallenge: LeaveTheChallengeFunction;
  incrementParticipantsCompletedStepSummary(): Promise<UpdatedUserData>;
  updateParticipant(
    participant?: Participant,
  ): Promise<Partial<IUserProviderProps>>;
  updateParticipantLockedState(): Promise<void>;
  requestMemberEmail(): Promise<void>;
}

export const userContextDefaultValue = {
  user: null,
  userType: null,
  isParticipantInSuspendedState: false,
  isParticipantInLockedState: false,
  participant: null,
  promptLogin: null,
  join: null,
  async incrementParticipantsCompletedStepSummary(): Promise<UpdatedUserData> {
    return {};
  },
  async updateParticipant() {
    return {};
  },
  async updateParticipantLockedState() {
    return {};
  },
  cancelJoinRequest: null,
  leaveProgram: null,
  leaveTheChallenge: null,
};

export function useUser(): IUserContext {
  const props = useControllerData();

  return {
    incrementParticipantsCompletedStepSummary:
      props.incrementParticipantsCompletedStepSummary,
    updateParticipant: props.updateParticipant,
    updateParticipantLockedState: props.updateParticipantLockedState,
    user: props.user,
    userType: props.userType,
    isParticipantInSuspendedState: props.isParticipantInSuspendedState,
    participant: props.participant,
    participantV3: props.participantV3,
    promptLogin: props.promptLogin,
    join: props.join,
    cancelJoinRequest: props.cancelJoinRequest,
    leaveProgram: props.leaveProgram,
    leaveTheChallenge: props.leaveTheChallenge,
    requestMemberEmail: props.requestMemberEmail,
  };
}
