import React from 'react';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import {
  ButtonSize as ButtonSIZE,
  TextButton,
  TextButtonPriority as TEXT_BUTTON_PRIORITY,
} from 'wix-ui-tpa/cssVars';

import { ParticipantStep } from '@wix/ambassador-challenges-v1-participant/types';
import utils, {
  ChallengeEntityType,
  getNextButtonLabel,
  isStepAvailableForComplete,
  isStepOverdue,
  isStepResolved,
} from '../../views/utils';
import { useResolveStep } from '../../../../../contexts/ResolveStep/ResolveStepContext';
import { PreviewActionBlockerContainer } from '../../../../../components-shared/ActionBlocker/PreviewActionBlockerContainer';
import { ActionBlockerAppearance } from '../../../../../components-shared/ActionBlocker/ActionBlocker';
import { useSettings } from '@wix/tpa-settings/react';
import challengeSettings from '../../../settingsParams';
import { IControl } from './types';
import { SidebarLayoutButton } from '../Buttons/SidebarLayoutButton/SidebarLayoutButton';
import { FedopsInteraction } from '../../../../../config/fedopsInteraction';
import { useMonitoring } from '../../../../../contexts/Monitoring/MonitoringContext';
import TextWithDirection from '../../../../../components-shared/TextWithDirection';

import { classes, st } from './StepControls.st.css';
import { UndoStepButton } from './UndoStepButton';
import { Box } from '../../../../../components-shared/Box';
import { useStepIsUnavailable } from './useStepIsUnavailable';
import { StepInTheFutureGuard } from '../StepInTheFutureGuard/StepInTheFutureGuard';
import { FCWithChildren } from '@wix/challenges-web-library';

export interface IStepControls extends IControl {
  completeBtnDataHook?: string;
  nextBtnDataHook?: string;
  currentStep: ParticipantStep;
  isCompletedChallenge: boolean;
  buttonState: string;
  onStepResolve(currentStep: ParticipantStep): Promise<void>;
  onFeedbackView(currentStep: ParticipantStep): Promise<void>;
  ShareButton: () => React.ReactNode;
}

export const StepControls: FCWithChildren<IStepControls> = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const { isResolveStepRequestInProgress } = useResolveStep();
  const { startInteraction } = useMonitoring();
  const stepId = props.currentStep?.id;
  const stepIndividualSettings =
    props.currentStep?.source?.settings?.general?.individual;
  const isQuizOnly =
    !stepIndividualSettings?.confirmationRequired &&
    stepIndividualSettings?.showQuiz;
  const isStepUnavailable = useStepIsUnavailable();

  const nextEntity = utils.getNextEntity(props.currentStep?.id, {
    listParticipantSections: props.sections,
    participantSteps: props.steps,
  });

  const isCompleteButtonShown =
    !props.isCompletedChallenge &&
    (isStepAvailableForComplete(props.currentStep) ||
      isStepOverdue(props.currentStep));

  const isFeedbackFormRequired = utils.isFeedbackFormRequired(
    props.currentStep,
  );

  const CompleteButton = isCompleteButtonShown ? (
    <PreviewActionBlockerContainer
      appearance={ActionBlockerAppearance.PopoverAppearance}
      content={t('action-blocker.preview-mode.complete-step')}
      isActionAllowed={utils.isFeedbackFormRequired(props.currentStep)}
    >
      <SidebarLayoutButton
        className={classes.stepViewMainCTA}
        dataHook={props.completeBtnDataHook || null}
        disabled={isResolveStepRequestInProgress}
        fullWidth={isMobile}
        size={isMobile ? ButtonSIZE.small : ButtonSIZE.medium}
        onClick={async () => {
          if (!isResolveStepRequestInProgress) {
            if (!isFeedbackFormRequired) {
              startInteraction(
                FedopsInteraction.CompleteStepWithoutFeedback,
                props.currentStep?.id,
              );
            }

            await props.onStepResolve(props.currentStep);
          }
        }}
      >
        {settings.get(challengeSettings.completeButtonTxt) as string}
      </SidebarLayoutButton>
    </PreviewActionBlockerContainer>
  ) : null;

  const NextButton =
    isStepResolved(props.currentStep) &&
    nextEntity.type !== ChallengeEntityType.VOID ? (
      <SidebarLayoutButton
        className={classes.stepViewMainCTA}
        key={stepId}
        dataHook={props.nextBtnDataHook || null}
        disabled={isResolveStepRequestInProgress}
        fullWidth={isMobile}
        size={isMobile ? ButtonSIZE.small : ButtonSIZE.medium}
        onClick={async () => {
          await props.onNextEntity(nextEntity);
        }}
      >
        {getNextButtonLabel({
          entity: nextEntity.type,
          t,
          nextButtonTxt: settings.get(
            challengeSettings.nextButtonTxt,
          ) as string,
        })}
      </SidebarLayoutButton>
    ) : null;

  const ViewQuestionnaireButton =
    isStepResolved(props.currentStep) &&
    utils.isFeedbackFormRequired(props.currentStep) ? (
      <TextButton
        className={classes.viewQuestionnaire}
        priority={TEXT_BUTTON_PRIORITY.link}
        onClick={async () => {
          if (!isResolveStepRequestInProgress) {
            await props.onFeedbackView(props.currentStep);
          }
        }}
      >
        {isQuizOnly
          ? t('challenge.page.step.completed.see-quiz')
          : t('challenge.page.step.completed.see-feedback')}
      </TextButton>
    ) : null;

  if (isStepUnavailable) {
    return null;
  }

  return (
    <div
      className={st(classes.root, {
        mobile: isMobile,
        align: settings.get(challengeSettings.sidebarLayoutTextAlignment),
        buttonState: props.buttonState,
        stepResolved: isStepResolved(props.currentStep),
      })}
    >
      <StepInTheFutureGuard>
        {isStepResolved(props.currentStep) ? (
          <>
            {isMobile ? (
              <>
                <p className={classes.stepCompletedText}>
                  <TextWithDirection>
                    {t('challenge.page.step.completed')}
                  </TextWithDirection>
                </p>
                {NextButton}
                {ViewQuestionnaireButton}
              </>
            ) : (
              <>
                <Box gap={6}>
                  {NextButton}
                  <UndoStepButton>{t('live-site.step.undo')}</UndoStepButton>
                </Box>
                <p className={classes.stepCompletedText}>
                  <TextWithDirection>
                    {t('challenge.page.step.completed')}
                  </TextWithDirection>
                </p>
                {ViewQuestionnaireButton}
              </>
            )}
          </>
        ) : null}

        {CompleteButton}
        {!isMobile ? props.ShareButton() : null}
      </StepInTheFutureGuard>
    </div>
  );
};
